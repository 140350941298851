import routes from './routes';
import createApp from './createApp';
import Store from './Store';
import Thread from '../models/Thread';
import Forum from '../models/Forum';
import Post from '../models/Post';

export default class Application {
    payload;
    main;
    router;
    forum = {};
    store = new Store({
        forums: Forum,
        threads: Thread,
        posts: Post,
    });
    constructor() {
        routes(this);
    }
    load(payload) {
        this.payload = payload;

        // this.store.pushPayload({data: this.payload.resources});

        // this.forum = this.store.getById('forums', 1);
    }
    boot() {
        this.mount();
    }

    mount() {
        createApp(this);
    }
}