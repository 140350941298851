import { ElContainer, ElMain } from 'element-plus'

export default {
    components: { ElContainer,ElMain },
    setup() {

    },
    render() {
        return (
            <el-container>
                <el-main>Hello Huoban Apps</el-main>
            </el-container>
        );
    },
}