import Model from "../common/Model";

export default class Threads extends Model {}

Object.assign(Threads.prototype, {
    id() {
        return this.data.id;
    },
    title: Model.attribute('title'),
    firstPost: Model.hasOne('firstPost'),
    isDeleted: Model.attribute('isDeleted')
});