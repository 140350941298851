import Index from '../components/IndexPage';
import About from '../components/AboutPage';
import Main from '../components/Main';
import { createRouter, createWebHistory } from 'vue-router';

export default function(app) {
    const routes = [
        { path: '/', component: Index },
        { path: '/about', component: About },
    ];

    app.main = Main;

    app.router = createRouter({
        history: createWebHistory(),
        routes,
    });
}